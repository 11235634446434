// After a pretty deep dive I've ended up with this:
// 1. Browser spec URL object isn't really equipped to concat multiple parts.
//    Which would leave us doing manual concats. Which are bad and how I kept
//    introducing bugs into this.
//
// 2. The most commonly used existing package (url-join) has issues. "There are
//    many like it but this one is ours":
//    https://github.com/jfromaniello/url-join/issues/10#issuecomment-1323933425
//
//    Tl;dr: It's important for our apps that any trailing slash is preserved
//    before query params. So this was adapted from url-join and has been
//    refined quite a bit. It categorizes url parts and makes sure they're
//    properly delimited, but doesn't actually parse or validate.
//
// 3. As long as we put the parts in the correct order (fragment and query
//    params 👀👀👀) this does a nice enough job and is fairly small. So we can
//    use it for basic joins across all url parts.
//
// 4. The URL constructor is well equipped to handle parsing and deconstruction.
//    (new URL(url).hostname)

export const joinUrl = (...parts) => {
  if (Array.isArray(parts[0])) {
    parts = [...parts[0]]
  }

  if (parts.length === 0) {
    return ''
  }

  if (typeof parts[0] !== 'string') {
    throw new TypeError(`Url must be a string. Received ${typeof parts[0]}`)
  }

  // Do an extra traversal to get this out of the way
  parts = parts.filter(part => {
    if (typeof part !== 'string') {
      throw new TypeError(`Url must be a string. Received ${typeof part}`)
    }
    // Skip empty strings
    return Boolean(part)
  })

  // If the first part is a plain protocol, we combine it with the next part.
  // This is so slashes aren't interfered with when joining slugs
  if (parts[0].match(/^[^/:]+:\/*$/) && parts.length > 1) {
    const protocol = parts[0]
    // Mutation
    parts.shift()
    parts[0] = protocol + parts[0]
  }

  // There must be two or three slashes in the file protocol. Two slashes in
  // anything else.
  if (parts[0].match(/^file:\/\/\//)) {
    parts[0] = parts[0].replace(/^([^/:]+):\/*/, '$1:///')
  }
  else {
    parts[0] = parts[0].replace(/^([^/:]+):\/*/, '$1://')
  }

  // TODO: Handle ports

  // Used to preserve the slash before query params. (The primary reason we
  // can't use url-join).
  let wasPrecededBySlash = false
  let containsQueryParams
  let containsFragment
  let url = ''

  let i = 0
  while (i < parts.length) {
    let part = parts[i]

    // Process query params differently. (But parse part as a slug in cases like
    // '/search?query=foo'.)
    // Double regex is easier to understand and work on than a thorough negative
    // lookbehind.
    const hasQuery = /\?|&|#[^!]/g.test(part)
    if (hasQuery && /^(?:\?|&|#[^!])/g.test(part)) {
      break
    }

    // If this isn't the last part, remove any trailing slashes
    if (i < parts.length - 1) {
      // Meaning: the *next* part was preceded by a slash. Preserves trailing
      // slashes before query params.
      wasPrecededBySlash = part[part.length - 1] === '/'
      if (wasPrecededBySlash) {
        part = part.replace(/\/+$/, '')
      }
    }
    // If it is the last part, dedupe
    else {
      part = part.replace(/\/+$/, '/')
    }

    // If this isn't the first part, remove preceding slashes
    if (i > 0) {
      part = part.replace(/^\/+/, '')
    }
    // Dedupe
    else {
      part = part.replace(/^\/+/, '/')
    }

    // Join slugs with slashes. Protocol was handled above. If this is the first
    // part then preceding slashes were already deduped. We removed existing
    // slashes already.
    if (url) {
      url += '/'
    }
    url += part

    i++

    // Stop parsing as path if the previous part included query params
    if (hasQuery) {
      containsQueryParams = true
      break
    }
  }

  // Preserve trailing slashes before query params
  if (i < parts.length && wasPrecededBySlash) {
    url += '/'
    wasPrecededBySlash = false
  }

  // Almost wish we could use URLSearchParams, but that would add the
  // requirement that either params must be in concise, properly formatted k,v
  // pairs, or that we do aggressive parsing. Which is usually a footgun.
  //
  // To be fair, this may look like a lot of parsing but it's actually not. We
  // don't really validate or check anything. This is just basic concatenation
  // of various url parts with the correct delimiters. It's long because the
  // spec is complex. But it's not trying to be particularly smart, or do
  // any other jobs.

  // Process query params starting at current index
  while (i < parts.length) {
    let part = parts[i]

    // TODO: Doesn't handle inputs like ['#', 'fragment']
    containsFragment = /#[^!]/g.test(part)

    // Process fragments differently
    if (containsFragment && !/\?|&/g.test(part)) {
      break
    }

    // Remove trailing delimiters. (Question mark might trail on poorly
    // formatted slug. E.g. '/search?')
    part = part.replace(/[?|&]+$/, '')
    // Remove leading delimiters
    part = part.replace(/^[?|&]+/, '')

    // Concat params with appropriate delimiter
    url += (!containsQueryParams ? '?' : '&') + part
    containsQueryParams = true

    i++

    if (containsFragment) {
      break
    }
  }

  // Concat remaining strings, if there are multiple parts to the fragment for
  // some reason
  while (i < parts.length) {
    // TODO: Maybe throw if there's something out of order, like query params
    // after a fragment. Maybe throw at earlier stages for similar issue.
    url += parts[i]
    i++
  }

  return url
}

// trimUrlPart accepts a string, removes any leading and trailing '/' characters
// and returns this new string
export const trimUrlPart = part => {
  if (!part) {
    return part
  }

  let trimmed = part

  // remove leading '/'
  if (trimmed[0] === '/') {
    trimmed = trimmed.substring(1)
  }

  // remove trailing '/'
  if (trimmed[trimmed.length - 1] === '/') {
    trimmed = trimmed.slice(0, -1)
  }

  return trimmed
}
