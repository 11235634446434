import IframeWhisperer from './index.js'

// This is the interface an iframe receives to enable communication with the
// parent.
export default class ParentIframeWhisperer extends IframeWhisperer {
  rafHandle
  lastHeight
  postMessageFn = window.parent.postMessage.bind(window.parent)
  #baseFontSize

  constructor (options = {}) {
    super(options)

    // Notify the parent of load
    this.notify({
      action: 'iframeWhisperer.handshake',
      payload: true,
    })

    const { autoHeight = true } = options

    if (autoHeight) {
      this.startHandlingHeight()
    }

    this.on('iframeWhisperer.removeEl', this.removeEl)
    this.on('iframeWhisperer.removeElAll', this.removeElAll)
    this.on('iframeWhisperer.handshake', this.handshake)
  }

  startHandlingHeight () {
    // No double scrollbars please
    document.documentElement.style.overflow = 'hidden'
    // Set this so we can catch the height of absolute/fixed position elements.
    document.body.style.position = 'relative'
    this.rafHandle = requestAnimationFrame(this.handleHeight)
  }

  stopHandlingHeight () {
    // Fine, have your double scrollbar
    document.documentElement.style.overflow = null
    document.body.style.position = null
    cancelAnimationFrame(this.rafHandle)
  }

  handleHeight = () => {
    let height
    // Check for the existence of absolute/fixed position elements
    // at the bottom of the page. Use scrollHeight only when needed.
    if (document.documentElement.offsetHeight >= document.body.scrollHeight) {
      height = document.documentElement.offsetHeight
    }
    else {
      // Add a buffer for elements that don't affect scroll (like box shadow).
      const buffer = this.#remToPx(3)
      height = document.body.scrollHeight + buffer
    }

    if (this.lastHeight !== height) {
      this.notify({
        action: 'iframeWhisperer.heightChanged',
        payload: height,
      })

      this.lastHeight = height
    }

    this.rafHandle = requestAnimationFrame(this.handleHeight)
  }

  // Handles backup handshake event sent by the parent
  handshake () {
    // Could potentially add flags to the child indicating success/failure.
    return true
  }

  sendLoadError (payload = true) {
    this.notify({
      action: 'iframeWhisperer.loadError',
      payload,
    })
  }

  removeEl (selector) {
    const el = document.querySelector(selector)
    if (!el) {
      return
    }

    el.remove()
  }

  removeElAll (selector) {
    const els = document.querySelectorAll(selector)
    if (!els.length) {
      return
    }

    els.forEach(el => {
      el.remove()
    })
  }

  /**
   * Helper that converts font root em size to font pixel size.
   * @param {Number} rem Rem size.
   * @returns {Number} Calculated pixel size.
   */
  #remToPx (rem) {
    if (typeof this.#baseFontSize === 'undefined') {
      this.#baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
    }
    return rem * this.#baseFontSize
  }

  // Clean up so garbage collection works
  destroy (...args) {
    this.stopHandlingHeight()
    super.destroy(...args)
  }
}
