import { v4 as uuid } from 'uuid'

export default class IframeWhisperer {
  messageSource = 'gsg-iframe-whisperer'
  actions = {}
  _promiseMap = {}

  // This method will be overridden in a subclass.
  postMessageFn = () => {}

  constructor ({ actions = {}, allowReceiveOrigin, messageSource } = {}) {
    if (messageSource) {
      this.messageSource = messageSource
    }

    Object.entries(actions).forEach(([key, handlers]) => this.on(key, handlers))

    if (allowReceiveOrigin) {
      if (typeof allowReceiveOrigin !== 'function') {
        throw new TypeError('allowReceiveOrigin must be a function if provided.')
      }
      this.allowReceiveOrigin = allowReceiveOrigin
    }

    window.addEventListener('message', this.onMessage)
  }

  // Add action handlers after instantiation
  on (key, handlers) {
    if (!Array.isArray(handlers)) {
      handlers = [handlers]
    }
    if (handlers.every(handler => typeof handler !== 'function')) {
      throw new TypeError('Action must be a function.')
    }

    this.actions[key] = [...(this.actions[key] || []), ...handlers]
  }

  // Remove a specific action handlers after instantiation
  // This still supports, for backwards compatibility, removing all handlers
  // when only a key is passed. But that use is deprecated.
  off (key, handler) {
    if (!handler) {
      console.warn('Warning: Calling IframeWhisperer.prototype.off() without a handler is deprecated.')
      this.offAll(key)
      return
    }

    this.actions[key] = this.actions[key]?.filter(fn => fn !== handler)
  }

  // Remove all handlers
  offAll (key) {
    const { [key]: removedHandlers, ...actions } = this.actions
    this.actions = actions
  }

  onMessage = async ({ data: { id, source, action, payload, err }, origin }) => {
    // err is created when the other side calls it's `respond()`

    // We only care about messages from the iframe interface.
    if (source !== this.messageSource) {
      return
    }

    // Ignore messages from unexpected origins
    if (this.allowReceiveOrigin && !this.allowReceiveOrigin(origin)) {
      return
    }

    // If this is a response, resolve the promise with the result and remove the
    // id from our promiseMap.
    if (this._promiseMap[id]) {
      const { [id]: { resolve, reject }, ...promiseMap } = this._promiseMap
      if (err) {
        reject(payload)
      }
      else {
        resolve(payload)
      }
      this._promiseMap = promiseMap
      return
    }

    const respond = id ? (payload, err) => this.notify({ payload, id, err }) : () => {}

    // If we have action handlers for this message, respond with the results.
    const handlers = this.actions[action]
    if (handlers) {
      try {
        if (handlers.length === 1) {
          respond(await handlers[0]?.(payload))
        }
        else {
          respond(await Promise.allSettled(this.actions[action].map(handler => handler?.(payload))))
        }
      }
      catch (error) {
        console.error(error)
        // Respond with error
        respond(error, true)
      }
      return
    }

    const error = `Unrecognized iframe whisperer message "${action}" received`
    console.error(error)

    // If a message hasn't been handled already, respond anyways to resolve any
    // promises on the other side.
    respond(error, true)
  }

  // Emit data when you don't care about a response. Optionally include an id to
  // respond to a message from the other side.
  notify ({ id, action, payload, err }) {
    // Older browsers (i.e. Safari < 16.6) are unable to serialize errors in
    // postMessage so we need to manually do it for them. More recent browsers handle this automatically.
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
    // "payload" holds the actual error, not err
    if (payload instanceof Error) {
      payload = JSON.stringify(payload, Object.getOwnPropertyNames(payload))
    }

    this.postMessageFn({
      source: this.messageSource,
      id,
      action,
      payload,
      err,
    }, '*')
  }

  // Same as notify but returns a promise.
  message ({ id = uuid(), ...options }) {
    return new Promise((resolve, reject) => {
      this._promiseMap[id] = { resolve, reject }

      this.notify({
        id,
        ...options,
      })
    })
  }

  // Clean up so garbage collection works
  destroy () {
    window.removeEventListener('message', this.onMessage)
  }
}
