import ToParent from './iframe-whisperer/parent.js'
import ToChild from './iframe-whisperer/child.js'

const isInIframe = () => window.location !== window.parent.location

const iframeWhisperer = (...args) => {
  console.warn('calling the default function of iframeWhisperer is deprecated, please use .ToParent() or .ToChild()')
  return isInIframe() ? new ToParent(...args) : new ToChild(...args)
}

iframeWhisperer.isInIframe = isInIframe
iframeWhisperer.ToParent = ToParent
iframeWhisperer.ToChild = ToChild

export default iframeWhisperer
