import IframeWhisperer from './index.js'

// This is the interface the embedding application receives to enable
// communication with the iframe.
export default class ChildIframeWhisperer extends IframeWhisperer {
  iframe
  awaitHeight = false
  requireHandshake = false
  handshakePromise
  resolveHandshakePromise
  heightPromise
  resolveHeightPromise
  heightTimeout = 1000
  handshakeTimeout = 500
  loaded = false
  // Don't initialize
  failed
  preLoadActions = []

  constructor (options = {}) {
    super(options)

    const { iframe, awaitHeight = false, requireHandshake = false } = options

    this.iframe = iframe
    this.postMessageFn = iframe.contentWindow.postMessage.bind(iframe.contentWindow)

    this.awaitHeight = awaitHeight
    if (awaitHeight) {
      // Politely wait a maximum of 1s (see below). This doesn't cause a failure
      // on timeout, we just continue on.
      // TODO: Emit an event on timeout
      this.heightPromise = new Promise(resolve => {
        this.resolveHeightPromise = () => {
          this.resolveHeightPromise = null
          resolve()
        }
      })
    }

    // When the child loads it will attempt to handshake with the parent.
    //
    // There's no way to tell if an iframe failed to load it's content. It's part
    // of the spec. https://bugs.chromium.org/p/chromium/issues/detail?id=365457
    // So we *require* a handshake from the child side api or else we assume the
    // document wasn't accessed properly and fully bail out.
    //
    // This used to work the other way around but we ran into issues in
    // PSC-17600. Different browsers handle queuing iframe load events
    // differently when the src doesn't exist initially or when it changes.
    // Tl;dr sometimes safari (at least) fires the iframe load event before the
    // child document has executed js initiating the handshake before the child
    // can receive it. Details here: https://github.com/whatwg/html/issues/490
    this.requireHandshake = requireHandshake
    if (requireHandshake) {
      this.handshakePromise = new Promise((resolve, reject) => {
        this.resolveHandshakePromise = () => {
          this.resolveHandshakePromise = null
          resolve(true)
        }
      })
    }
    this.on('iframeWhisperer.handshake', () => this.resolveHandshakePromise?.())

    iframe.addEventListener('load', this.iframeLoaded)
    // If the caller did not provide a loaded action
    // create our own no-op so we don't warn when we receive this event
    if (!options.actions?.['iframeWhisperer.loaded']) {
      this.on('iframeWhisperer.loaded', () => {})
    }

    this.on('iframeWhisperer.heightChanged', this.handleHeight)
  }

  // On HTML element load event
  iframeLoaded = async () => {
    // Max timeout for height information
    // Start this race after the DOM event, not in the constructor
    if (this.resolveHeightPromise) {
      setTimeout(this.resolveHeightPromise, this.heightTimeout)
    }

    let shook
    try {
      ([shook] = await Promise.all([
        // Require a handshake from the child
        this.requireHandshake ? Promise.race([
          // Wait for the child to shake
          this.handshakePromise,
          // Send a backup message just in case
          this.initHandshake(),
          // Wait a maximum time before giving up.
          // (Doesn't reject, resolves false)
          new Promise(resolve => setTimeout(() => resolve(false), this.handshakeTimeout)),
        ]) : true,

        // If !awaitHeight then there will be nothing to await
        this.heightPromise,

        // XXX: It might be tempting to combine those but turns out it's notably
        // worse.
        // 1. Results in much less readable code.
        // 2. The child doesn't have accurate height info to send until after a
        //    raf. So we'd have to wait for it or accept a FOUC (flash of
        //    unstyled content) as the height changes. And the latter would
        //    erase the benefit of the heightPromise.
        // 3. This way we can detect a full failure earlier than we would be if
        //    we waited for a raf.
      ]))
    }
    catch (error) {
      console.error(error)
      this.failed = true
    }

    // TODO: Compare awaitHeight setting with child, and emit an error/warning
    // if they don't match. Also error on child side?

    this.failed = this.failed || !shook

    if (this.failed) {
      // Load failed completely
      this.offAll('iframeWhisperer.heightChanged')
      // Send a failure event *to ourselves*
      this.onMessage({
        data: {
          action: 'iframeWhisperer.loadFailure',
          source: this.messageSource,
        },
        origin: window?.location?.origin,
      })
      return
    }

    // Send messages queued before both sides were initialized
    const actions = this.preLoadActions
    this.preLoadActions = []
    actions.forEach(action => action())

    this.loaded = true
    // Send a message to ourselves to indicate that load is complete *and
    // successful*. The element will emit a load event for either success for
    // failure. We should only emit emit on success.
    this.onMessage({
      data: {
        action: 'iframeWhisperer.loaded',
        source: this.messageSource,
      },
      origin: window?.location?.origin,
    })
  }

  // This is only split out so it's easy to override for automated testing
  initHandshake = () => this.message({ action: 'iframeWhisperer.handshake', immediate: true })

  handleHeight = height => {
    this.iframe.style.height = `${height}px`
    this.resolveHeightPromise?.(height)
  }

  removeEl (selector) {
    this.notify({
      action: 'iframeWhisperer.removeEl',
      payload: selector,
    })
  }

  removeElAll (selector) {
    this.notify({
      action: 'iframeWhisperer.removeElAll',
      payload: selector,
    })
  }

  // The iframe may take a while to load so queue up any message or notify
  // requests so they can be executed when it does.
  notify (options, ...args) {
    if (!this.loaded && !options.immediate) {
      this.preLoadActions = [
        ...this.preLoadActions,
        () => super.notify(options, ...args),
      ]

      return
    }

    super.notify(options, ...args)
  }

  async message (options, ...args) {
    if (!this.loaded && !options.immediate) {
      await new Promise(resolve => {
        this.preLoadActions = [
          ...this.preLoadActions,
          resolve,
        ]
      })
    }

    return super.message(options, ...args)
  }
}
