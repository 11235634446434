/**
 * Asserts that the passed parameter value is defined, throwing an error
 * otherwise.
 *
 * This only accepts one variable in order to use TypeScript assertions
 * (everything that comes after this function call can assume that the parameter
 * is defined).
 */
export function assertRequiredParameter (
  parameterName: string,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
   * We explicitly want to allow `any` because we want to validate any type of
   * parameter.
   */
  value: any,
  moduleName: string,
): asserts value {
  if (value === undefined) {
    throw new Error(`${moduleName} Error: You must pass \`${parameterName}\``)
  }
}

// TODO: PSC-22426 - Maybe move this to a shared place
type NonNullableProperties<T> = { [K in keyof T]: NonNullable<T[K]> }

/**
 * Ensures that all parameters on an object are defined. Use this to
 * assert that multiple parameters are defined.
 * ;({
 *   one,
 *   two,
 *   three,
 * } = ensureRequiredProperties({
 *   one,
 *   two,
 *   three,
 * }, 'module Abc'))
 *
 * @param {Params extends Record<string, any>} params - The parameters to be
 * validated.
 * @param {string} moduleName - The name of the module for error messages.
 * @return {NonNullableProperties<Params>} - Returns params object.
 * @throws {TypeError} = Throws an error if any property on params is undefined.
 */
export function ensureRequiredParameters<
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
   * We explicitly want to allow `any` because we want to validate any type of
   * parameter.
   */
  Params extends Record<string, any>
> (
  params: Params,
  moduleName: string,
): NonNullableProperties<Params> {
  for (const [name, value ] of Object.entries(params)) {
    if (value === undefined) {
      throw new TypeError(`${moduleName} Error: You must pass \`${name}\``)
    }
  }
  return params
}
